#not-found-wrapper{
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 75vh
}

#not-found-wrapper span.fa{
  font-size: 80px;
  color: var(--ui-color);
}

#not-found-wrapper p.error-text{
  color: var(--ui-text);
  margin-top: 20px;
}
