:root{
  --main-color: #F81722;
  --main-bg: #fff;
  --main-border: #d9d9d9;
  --main-text: #333;

  --main-color-helper: #D31722;
  --main-bg-helper: #efefef;
  --main-border-helper: #d9d9d9;

  --ui-color: #0D3165;
  --ui-bg: #e4e4e4;
  --ui-border: #e4e4e4;
  --ui-text: #000;
  --ui-placeholder: #8a8888;

  --ui-color-helper: #0D314B;
  --ui-bg-helper: #d4d4d4;
  --ui-border-helper: #d4d4d4;
  --ui-placeholder-helper: #999;


  --valid-color: #44b344;
  --invalid-color: orangered;
  --warning-color: orange;


  --ui-bg-hvr: red;
  --ui-color-hvr: red;

}
