#page{
  padding: 20px;
}

#page > .title{
  font-size: var(--font-xl);
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1.2
}
