.button{
  padding: 10px 15px;
  font-size: var(--font-s);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--ui-bg);
  border: 1px solid var(--ui-border);
  color: var(--ui-text);
  cursor: pointer;
  margin: 0;
  border-radius: 2px;
  outline: none;
  transition: all .2s ease-in-out;
}

.button:disabled{
  cursor: not-allowed;
}

.button > span{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:hover{
  border-color: var(--ui-border-helper);
  background: var(--ui-bg-helper);
}

.button.main{
  border-color: var(--main-color);
  color: var(--main-bg);
  background: var(--main-color);
  font-weight: bold;
}

.button.main:hover{
  border-color: var(--main-color-helper);
  background-color: var(--main-color-helper);
}

.button .loader{
  margin: 0;
  width: var(--font-s);
  height: var(--font-s);
  border-width: 3px;
  margin-right: 10px;
}

.button.full{
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}

.button.full .loader{
  position: absolute;
  left: -25px;
}
