.references-slider{
  margin: 80px 0 0;
}
.references-slider .references-slider-item .image{
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.references-slider .references-slider-item .image img{
  height: 100%;
  width: auto;
}

.references-slider .slick-dots{
  bottom: -80px;
}
