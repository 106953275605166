#promotions-modal .modal-content{
  height: auto;
  overflow: hidden;
}

#promotions-modal .modal-content embed{
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

#promotions-modal #gamingmatic img {
  max-height: 41vh;
  margin-right: 40px;
  float: left;
}

#promotions-modal h3 {
  font-size: 22px;
}

#promotions-modal p {
  line-height: 1.125em;
}

#promotions-modal p strong {
  font-weight: bold;
}

iframe::-webkit-scrollbar {
     display: none;
}

@media only screen and (max-width: 800px) {
  #promotions-modal .modal-content {
    overflow-y: auto;}
  #gamingmatic img {
    margin-bottom: 20px;
  }
}