.close-button{
  position: absolute;
  background: var(--main-bg);
  right: 10px;
  top: 10px;
  border: 2px solid var(--ui-border);
  color: var(--ui-placeholder);
  border-radius: 50%;
  padding: 5px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  -webkit-transition: all linear 200ms;
  -moz-transition: all linear 200ms;
  -o-transition: all linear 200ms;
  transition: all linear 200ms;
  z-index: 100;
}

.close-button:hover{
  border-color: var(--main-color);
  color: var(--main-color);
  cursor: pointer;
}
