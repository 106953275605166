#product .product{
  display: flex;
  margin-top: 20px;
}

#product .title h1{
  display: flex;
  align-items: center;
  justify-content: center;
}

#product .info{
  margin-right: 60px;
  margin-left: 20px;
  width: 100%;
}

#product .info .button{
  margin: 20px auto 0;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}

#product .desc h3{
  text-align: center;
  font-weight: bold;
}

#product .desc ol{
  list-style: decimal;
  padding-left: 40px;
  margin: 10px 0;
}

#product .desc ol li{
  padding: 5px 0;
}

#product .desc ul{
  list-style: circle;
  padding-left: 20px;
  margin: 10px 0;
}

#product .desc ul li{
  padding: 5px 0;
}

@media only screen and (max-width: 1023px) {

#product{
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

#product .info{
    margin-left: 0;
    margin-right: 0;
  }

#product .image{
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
  }

#product .desc h3{
    text-align: center;
  }

}


#product-gallery{
  margin-top: 40px;
}

#product-gallery .title{
  font-size: var(--font-l);
  font-weight: bold;
  text-align: center;
  margin: 20px 0;
}

#product-gallery .images{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

#product-gallery .images .image{
  cursor: pointer;
  transition: all 0.3s ease-out;
  width: 250px;
  height: 250px;
  border: 1px solid var(--ui-border);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  margin-bottom: 20px;
}


#product-gallery .images .image:hover{
  transform: scale(1.1);
}

#product-gallery .image img{
  width: auto;
  height: 100%;
}
