.badge{
  background: var(--main-color);
  border-radius: 4px;
  padding: 4px;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 10px;
  margin-left: 10px;;
}

.products .product{
  display: flex;
  border: 1px solid var(--main-border);
  padding: 40px;
  box-sizing: border-box;
  margin-bottom: 40px;
  align-items: center;
}

.products .product:last-child{
  margin-bottom: 0;
}

.products .product .image{
  width: 250px;
}

.products .product .title{
  font-size: var(--font-m);
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  border-bottom: 1px solid var(--ui-border);
  padding-bottom: 15px;
}

.products .product .title h2{
  display: flex;
  align-items: center;
  justify-content: center;
}

.products .product .info{
  margin-right: 60px;
  width: 100%;
}

.products .product .info .button{
  margin: 20px auto 0;
  width: 100%;
  padding: 15px;
}

.products .product .desc h3{
  text-align: center;
  font-weight: bold;
}

.products .product .desc ol{
  list-style: decimal;
  padding-left: 40px;
  margin: 10px 0;
}

.products .product .desc ol li{
  padding: 5px 0;
}

.products .product .desc ul{
  list-style: circle;
  margin: 10px 0;
}

.products .product .desc ul li{
  padding: 5px 0;
}

@media only screen and (max-width: 1023px) {

  .products .product{
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .products .product .image{
    width: auto;
  }

  .products .product .info{
    margin-right: 0;
  }

  .products .product .image{
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
  }

  .products .product .desc h3{
    text-align: center;
  }

}
