#home-news{
  background: var(--ui-color);
  color: white;
}

#home-news .sub .image{

}

#home-news .sub .image img{
  height: 400px;
  width: auto;
}
