#footer-wrapper{
  box-sizing: border-box;
  margin-top: 40px;
  border-top: 1px solid var(--main-border);
  background: var(--main-bg-helper)
}

/***** FOOTER BAR *****/

#footer-bar{
  padding: 80px 20px;
  border-bottom: 1px solid var(--main-border-helper)
}

#footer-bar .boxes{
  display: flex;
}

#footer-bar .box{
  flex-basis: calc(40% - 20px);
  padding: 20px;
  box-sizing: border-box;
  margin: 0 10px;
}

#footer-bar .box.double{
  flex-basis: calc(60% - 20px);
}

#footer-bar .box .title{
  font-weight: 900;
  font-size: var(--font-m);
  margin-bottom: 20px;
}

#footer-bar .box nav.menu{
  display: flex;
  flex-direction: column;
}

#footer-bar .box nav.menu a{
  line-height: 1.5;
  transition: all 0.3s ease-out;
}

#footer-bar .box nav.menu a:hover{
  color: var(--main-color)
}

#footer-bar .map{
  height: 360px;
  width: 100%;
}


/***** FOOTER *****/

#footer{
  display: flex;
  padding: 20px;
  align-items: center;
}

#footer #logo img{
  height: 40px;
}

#footer #copyright{
  margin-left: auto;
  color: var(--ui-placeholder)
}

#footer #footer-menu{
  margin-left: 20px;
}

#footer #footer-menu ul{
  display: flex;
  align-items: center;
}

#footer #footer-menu ul li{
  margin-right: 10px;
  color: var(--ui-placeholder);
  transition: all 0.3s ease-out;
}

#footer #footer-menu ul li:not(.separator){
  cursor: pointer;
}

#footer #footer-menu ul li:hover:not(.separator){
  color: var(--ui-color)
}

@media only screen and (max-width: 1000px) {

  #footer-bar .boxes{
    flex-wrap: wrap;
  }

  #footer-bar .boxes .box{
    flex-basis: 100%
  }

}

@media only screen and (max-width: 600px) {

  #footer{
    flex-direction: column;
  }

  #footer #footer-menu ul{
    display: flex;
    flex-direction: column;
  }

  #footer #footer-menu ul li{
    margin: 5px 0;
  }

  #footer #footer-menu ul .separator{
    display: none;
  }

  #footer #copyright{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0;
    margin-top: 5px;
  }

}
