/* Slider */
.slick-slider{
  position: relative;
  padding: 0 0;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  outline: none;
}

.slick-slider *{
  outline: none;
}

.slick-list{
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus{
  outline: none;
}

.slick-list.dragging{
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list{
  transform: translate3d(0, 0, 0);
}

.slick-track{
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after{
  display: table;
  content: '';
}

.slick-track:after{
  clear: both;
}
.slick-loading .slick-track{
  visibility: hidden;
}

.slick-dots{
  position: absolute;
  bottom: 20px;
  display: flex;
  align-items: center;
}

.slick-dots li button::before{
  display: none;
}

.slick-dots li button{
  background: var(--ui-color);
  border-radius: 50%;
  transition: all 0.3s ease-out;
}


.slick-dots li button:hover{
  background: var(--ui-color-helper)
}

.slick-dots li.slick-active button,
.slick-dots li button:focus{
  background: var(--main-color);
}

.slick-slide{
    display: none;
    float: left;
    overflow: hidden;
    padding: 0;
    box-sizing: border-box;
    height: 100%;
    min-height: 1px;
}

[dir='rtl'] .slick-slide{
    float: right;
}

.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

.slick-arrow{
  top: -25px;
  right: 0;
  left: auto;
}

.slick-arrow.slick-prev{
  right: 20px;
}

.slick-arrow:before{
  color: var(--main-color);
}
