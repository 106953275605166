#header{
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  border-bottom: 1px solid var(--main-border);
  position: sticky;
  top: 0;
  background: var(--main-bg);
  z-index: 1000;
}

#header #user-menu{
  margin-left: auto;
}

#main-menu{
  display: flex;
}

#header .navigation-wrapper{
  margin-left: auto;
  height: 100%;
  display: flex;
  align-items: center;
}

#header .navigation-toggle{
  display: none;
  font-size: var(--font-l);
  color: var(--main-color);
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {

  #header .navigation{
    display: none;
    margin-left: 0;
  }

  #header .navigation.opened{
    display: block;
    background: var(--main-bg);
    position: absolute;
    left: 0;
    right: 0;
    top: 60px;
    bottom: 0;
    margin-left: 0;
    height: 100%;
    z-index: 999;
  }

  #header .navigation > *:last-child{
    border-bottom: 1px solid var(--main-border)
  }

  #header .navigation .nav-item{
    background: var(--main-bg);
    z-index: 900;
  }

  #header .navigation-toggle{
    display: block;
  }

}
