.image-row{
  display: flex;
  background: var(--main-bg-helper)
}

.image-row .bg{
  height: 500px;
  flex: 2;
  background-size: cover;
  background-repeat: no-repeat;
}

.image-row .sub{
  padding: 80px;
  box-sizing: border-box;
  flex: 1;
}

.image-row .sub .title h2{
  font-size: var(--font-xxl)
}
