.widgets.sticky{
  position: sticky;
  top: 80px;
}

.widget{
  border: 1px solid var(--main-border);
  box-sizing: border-box;
  margin: 0 0 20px;
}

.widget .widget-header{
  display: flex;
  align-items: center;
  padding: 20px;
  background: var(--main-bg-helper);
  text-transform: uppercase;
  font-size: var(--font-s);
  line-height: 1.4;
  font-weight: 900;
}

.widget .widget-header .toggle{
  display: none;
  margin-left: auto;
  cursor: pointer;
  font-size: var(--font-l);

}

.widget .widget-content{
  padding: 20px;
  transition: all 0.3s ease-out;
}

.widget .widget-content.no-padding{
  padding: 0
}

.widget .widget-footer{
  border-style: inset;
  padding: 20px;
}

.widget nav.menu{
  display: flex;
  flex-direction: column;
}

.widget nav.menu > *{
  padding: 15px;
  border-bottom: 1px solid var(--ui-border)
}

.widget nav.menu > *:last-child{
  border-bottom: 0;
}

.widget nav.menu > *:hover{
  background: var(--ui-bg)
}

@media only screen and (max-width: 760px) {

  .widgets{
    margin-top: 20px;
  }

  .widgets.sticky{
    position: relative;
    top: auto;
  }

  .widget .widget-content{
    max-height: 0;
    overflow: hidden;
  }

  .widget .widget-header .toggle{
    display: block;
  }

}
