#logo{
  color: var(--main-color);
  font-size: var(--font-xl)
}

#logo img{
  max-width: 100%;
  height: 53px;
  margin-top: 5px;
}

@media only screen and (max-width: 800px) {

  #logo img{
    height: 40px;
  }

}