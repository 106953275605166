#machinery .wysiwyg .text.float{
  width: 50%;
  float: left;
}

#machinery .wysiwyg .image.float{
  width: calc(50% - 20px);
  float: right;
  margin-left: 20px;
}

@media only screen and (max-width: 1023px) {

  #machinery .wysiwyg .text.float,
  #machinery .wysiwyg .image.float{
    width: 100%;
    margin: 0;
  }

}
