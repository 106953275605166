.jumbo{
  display: flex;
  position: relative;
  background: var(--main-bg);
  padding: 80px;
  text-align: center;
}

.jumbo .title h2{
  font-size: var(--font-xxl);
  text-align: center;
  font-weight: 900;
  margin: 0;
}

.jumbo .sub{
  margin: 40px 0 0;
  font-size: var(--font-m)
}

.jumbo b{
  font-weight: bold;
  margin: 10px 0;
  display: block;
}
