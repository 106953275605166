.modal{
  display: flex;
  flex-direction: row;
  border: 1px solid var(--ui-border);
  border-radius: 5px;
  position: relative;
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-info{
  width: 90vw;
  max-width: 720px;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.modal-info .header-image img{
  width: 100%;
  height: auto;
}

.modal-info .title h2{
  font-size: var(--font-xl);
}

.modal-header{
  padding: 15px;
  border-bottom: 1px solid var(--ui-border);
}

.modal-content{
  margin-bottom: 5px;
  padding: 40px;
}

.modal-footer{
  margin-top: auto;
  display: flex;
  min-height: 50px;
  padding: 15px;
  border-top: 1px solid var(--ui-border);
}

@media only screen and (max-width: 680px) {

  .modal-content{
    padding: 20px;
  }

}
