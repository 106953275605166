html{
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
  font-size: var(--font-xs);
  background: var(--main-bg);
}

body{
  margin: 0;
  padding: 0;
  font-family: 'Ubuntu', sans-serif;
  color: var(--main-text);
}

.container{

}

.row{
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.row.wrapped{
  max-width: 1280px;
}

.content-wrapper{
  display: flex;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.content-wrapper .content{
  flex: 1;
  padding: 20px 20px;
  box-sizing: border-box;
}

.content-wrapper .content .page-title{
  font-size: var(--font-xl);
  font-weight: bold;
  padding-bottom: 20px;
  margin: 20px 0;
  text-align: center;
}

.content-wrapper .sidebar.no-padding,
.content-wrapper .content.no-padding{
  padding: 0;
}

.content-wrapper .sidebar{
  width: 300px;
  box-sizing: border-box;
  padding: 20px 0;
  position: relative;
}

hr{
  border-color: var(--ui-border);
  margin: 10px 0;
  border: 0;
  border-bottom: 1px solid var(--main-border);
}

.ReactModal__Body--open {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.ReactModal__Overlay{
  z-index: 8000;
}

@media only screen and (max-width: 760px) {

  .content-wrapper{
    flex-wrap: wrap;
  }

  .content-wrapper > *{
    flex-basis: 100%;
  }

  .content-wrapper .content{
    padding: 20px 0;
  }

}

@media only screen and (max-width: 414px) {

  .content-wrapper{
    flex-wrap: wrap;
    justify-content: center;
  }

  .content-wrapper .sidebar{
    width: 100%;
    padding: 0;
  }

}
