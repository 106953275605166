.badge{
  background: var(--main-color);
  border-radius: 4px;
  padding: 4px;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 10px;
  margin-left: 10px;;
}

#promotions .product .desc h2{
  font-weight: bold;
}

#promotions .product .price-wrapper{
  margin: 20px 0;
  text-align: center;
}

#promotions .product .price-wrapper .price{
  font-size: var(--font-xl);
  color: var(--main-color);
  margin-bottom: 10px;
}

#promotions .product .price-wrapper .label{
  font-weight: bold;
}
