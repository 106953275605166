.navigation{
  display: flex;
  height: calc(100% + 1px);
  margin-left: 40px;
  margin-bottom: -1px
}

.navigation .nav-item{
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.4s linear;
  border-left: 1px solid var(--main-border);
  border-bottom: 3px solid transparent;
}

.navigation > .nav-item:last-child{
  border-right: 1px solid var(--main-border);
}

.navigation .selected .nav-item,
.navigation .nav-item:hover{
  background: var(--main-bg-helper)
}

.navigation .selected .nav-item{
  color: var(--main-color);
  border-bottom: 3px solid var(--main-color);
}
