.product-slider{
  margin:  0;
}

.product-slider .product-slider-item{
  display: flex;
  margin: 0 auto;
  justify-content: center;
  max-width: 1280px;
  position: relative;
  align-items: center;
}

.product-slider .product-slider-item .info{
  padding: 80px;
  box-sizing: border-box;
  margin-right: 80px;
  flex: 2;
  background: rgba(255,255,255, .4)
}

.product-slider .product-slider-item .info .title{
  font-size: var(--font-xxl);
  font-weight: bold;;
  margin-bottom: 20px;
}

.product-slider .product-slider-item .info .desc{
  font-size: var(--font-l);
  line-height: 1.2;
}

.product-slider .product-slider-item .info .button{
  width: 200px;
  padding: 15px;
  font-size: var(--font-m);
  margin-top: 20px;
}

.product-slider .product-slider-item .image{
  flex: 1
}

.product-slider .product-slider-item .image img{
  display: block;
  max-height: 600px;
  width: auto;
  max-width: none;
  padding: 40px;
  padding-right: 0;
}

.parallelogram {
	width: 200px;
  position: absolute;
  z-index: -1;
	height: 700px;
	transform: skew(-20deg);
	background: var(--ui-color);
  left: -300px;
}

.parallelogram2 {
	width: 200px;
  position: absolute;
  z-index: -1;
  bottom: -2px;
	height: 700px;
	transform: skew(-20deg);
	background: var(--main-color);
  right: -300px;
}

@media only screen and (max-width: 1200px) {

  .product-slider .product-slider-item .info{
    padding: 40px;
    margin-right: 0;
  }

}

@media only screen and (max-width: 800px) {

  .product-slider .product-slider-item{
    flex-wrap: wrap;
    text-align: center;
  }

  .product-slider .product-slider-item .image{
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .parallelogram,
  .parallelogram2{
    display: none;
  }

  .product-slider .product-slider-item .button{
    margin: 0 auto;
    margin-bottom: 20px;
  }

}
