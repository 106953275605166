.contact-card{
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: var(--font-xs);
}

.contact-card .contact-card-item{
  padding: 15px 0;
  font-size: var(--font-s);
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

.contact-card .contact-card-item:hover{
  background: var(--main-bg-helper);
}

.contact-card a:last-child .contact-card-item{
  border-bottom: 0;
}

.contact-card .contact-card-item .label{
  display: flex;
  flex-direction: column;
}

.contact-card .contact-card-item .label .info{
  font-size: var(--font-xs);
  color: var(--ui-placeholder);
  margin-bottom: 5px;
}

.contact-card .contact-card-item .fas,
.contact-card .contact-card-item .fa{
  font-size: var(--font-l);
  margin-right: 10px;
  width: 25px;
  color: var(--ui-color)
}
